
.btn-hamburger--concave {
    ::v-deep{
        > span {
            width: 20px;
            margin-right: 6px;
        }
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
}
@media (min-width:1200px){
    .btn-hamburger--concave {
        ::v-deep{
            > span {
                width: 22px;
            }
        }
    }
}
