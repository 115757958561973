
.num{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-width: 36px;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, .2);
}
@media (min-width:576px){
}
@media (min-width:768px){
    .num{
        min-width: 40px;
        width: 40px;
        height: 40px;
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}

