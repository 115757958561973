
// main-visual
.main-visual {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: relative;
    &__bg {
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-image: url(/images/main/visual.jpg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    &__inner {
        height: 100%;
        overflow: hidden;
        position: relative;
        z-index: 1;
        .main-visual__contents:not(:last-child) {
            &::after {
                content: "";
                display: block;
                position: absolute;
                width: 100%;
                height: 1px;
                background-color: rgba(255, 255, 255, 0.2);
                right: 0;
                bottom: 0;
                transform: scaleX(0);
                opacity: 0;
                visibility: hidden;
                animation: scaleX 1.5s 0.1s ease-out forwards;
            }
        }
        .main-visual__contents:nth-child(1) {
            &::after {
                transform-origin: left center;
            }
        }
        .main-visual__contents:nth-child(2) {
            &::after {
                transform-origin: right center;
            }
        }
    }
    &__contents {
        height: calc(100% / 3);
        position: relative;
        display: flex;
        align-items: center;
        &--1 {
            &__img {
                margin-top: auto;
                ::v-deep {
                    .v-image__image {
                        background-position: left bottom !important;
                    }
                }
            }
        }
        &--3 {
            &__inner {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                position: relative;
                .main-visual__caption {
                    position: absolute;
                    right: 16px;
                    bottom: 16px;
                }
            }
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    // main-visual
    .main-visual {
        &__inner {
            height: 100%;
            .main-visual__contents:not(:last-child) {
                &::after {
                    width: 1px;
                    height: 100%;
                    transform: scaleY(0);
                    animation: scaleY 1.5s 0.1s ease-out forwards;
                }
            }
            .main-visual__contents:nth-child(1) {
                &::after {
                    transform-origin: center top;
                }
            }
            .main-visual__contents:nth-child(2) {
                &::after {
                    transform-origin: center bottom;
                }
            }
        }
        &__contents {
            height: 100%;
            &--1 {
                &__img {
                    margin: 0;
                }
            }
            &--3 {
                &__inner {
                    .main-visual__caption {
                        right: unset;
                        left: 8px;
                        bottom: 60px;
                    }
                }
            }
            &--3 {
                &__inner {
                    justify-content: flex-start;
                }
            }
        }
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
@keyframes scaleY {
    0% {
        opacity: 0;
        visibility: hidden;
        transform: scaleY(0);
    }
    100% {
        opacity: 1;
        visibility: visible;
        transform: scaleY(1);
    }
}
@keyframes scaleX {
    0% {
        opacity: 0;
        visibility: hidden;
        transform: scaleX(0);
    }
    100% {
        opacity: 1;
        visibility: visible;
        transform: scaleX(1);
    }
}
