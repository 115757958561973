
.main-gate {
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    &__caption {
        font-size: 1.2rem;
        line-height: 1;
        letter-spacing: 0.5em !important;
        font-family: var(--font-secondary);
        text-transform: uppercase;
        color: var(--v-grey-lighten1);
    }
    &__btn {
        background-color: #d69c61 !important;
        color: #fff;
        font-size: var(--tit-font-size-sm) !important;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .main-gate {
        height: 100vh;
        &__inner {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
        }
        &__caption {
            writing-mode: vertical-rl;
            width: fit-content;
        }
    }
}
@media (min-width: 1024px) {
    .main-gate {
        position: relative;
        &::after {
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-image: url(/images/symbol-light.svg);
            background-position: center bottom;
            background-repeat: no-repeat;
            background-size: 100% auto;
            opacity: 0;
            visibility: hidden;
            transition: 0.2s ease-out;
        }
        &__inner {
            background-color: transparent;
            transition: 0.2s ease-out;
        }
        &__wrap {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        &__btn {
            width: 240px;
            height: 68px !important;
        }
        &__caption {
            transition: 0.2s ease-out;
        }
    }
    .main-gate:hover {
        &::after {
            opacity: 1;
            visibility: visible;
        }
        .main-gate__inner {
            background-color: rgba(214, 156, 97, 0.7);
        }
        .main-gate__btn {
            background-color: #fff !important;
            color: #d69c61;
            position: relative;
            z-index: 999;
        }
        .main-gate__caption {
            color: #fff;
        }
    }
}
@media (min-width: 1200px) {
}
