
.footer {
    background-color: var(--v-grey-darken4);
    &__inner{
        background-image: url(/images/common/footer-bg.jpg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
}
@media (min-width:1200px){
    .footer {
        &__inner{
            padding-right: var(--header-width);
        }
    }
}

