
.introduction{

    --item-foot-height : 66px;

    position: relative;
    &__line-area{
        display: none;
        height: 100%;
        width: 100%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    &__title{
        position: relative;
        z-index: 1;
    }
    &__slide{
        width: 100%;
        position: relative;
        &::before{
            content: "";
            display: block;
            position: absolute;
            width: 100vw;
            height: 1px;
            background-color: rgba(255, 255, 255, .2);
            top: calc(50% - (var(--item-foot-height) / 2));
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
    &-item{        
        &__image{
            border-radius: 999px 999px 0 0;
            overflow: hidden;
            width: 100%;
            height: 0;
            padding-top: calc((660 / 440) * 100%);
            position: relative;
            transition: 0.5s ease-out;
            &__inner,
            &__contents,
            &::before{
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
            }
            &__contents{
                background-color: rgba(0, 0, 0, .5);     
                display: flex;
                align-items: center;
                justify-content: center;  
                opacity: 0;
                visibility: hidden;
                transition: 0.2s ease-out;
            }
            &::before{
                content: "";
                display: block;
                background-image: url(/images/symbol-light.svg);
                background-position: center bottom;
                background-repeat: no-repeat;
                background-size: 100% auto;
                z-index: 1;
                opacity: 0;
                visibility: hidden;
                transition: 0.2s ease-out;
            }
            &::after{
                content: "";
                display: block;
                position: absolute;
                width: 100%;
                height: 6px;
                left: 0;
                bottom: 0;
                z-index: 2;
                background: linear-gradient(270deg,#b18a00 0,#ffea9f 50%,#b18a00);
                opacity: 0;
                visibility: hidden;
                transform: scaleX(0);
                transition: 0.2s ease-out;
            }
            &__text{
                transform: scale(1.4);
                opacity: 0;
                visibility: hidden;
                transition: 0.2s ease-out;
            }
        }
        &__contents{
            opacity: 1;
            visibility: visible;
            transition: 0.2s ease-out;
        }
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .introduction{

        --head-height : 124px;
        --item-foot-height : 96px;

        &__line-area{
            display: block;
        }
        &__line{
            position: relative;
            top: calc(var(--page-section-padding-y) * -1);
            height: calc(100% + (var(--page-section-padding-y) * 2));
            // background-color: red;
            &__caption{
                position: absolute;
                right: 8px;
            }
            &::before{
                content: "";
                display: block;
                position: absolute;
                width: 1px;
                right: 0;
                background-color: rgba(255, 255, 255, .2);
            }
            &--1{
                .introduction__line__caption{
                    top: calc(var(--page-section-padding-y) + var(--tit-wrap-padding-bottom-lg) + var(--head-height) - 20px);
                    transform: translateY(-100%);
                }
                &::before{
                    top: 0;
                    height: calc(var(--page-section-padding-y) + var(--tit-wrap-padding-bottom-lg) + var(--head-height));
                }
            }
            &--2{
                .introduction__line__caption{
                    bottom: 20px;
                }
                &::before{
                    bottom: 0;
                    height: calc(var(--page-section-padding-y) + var(--item-foot-height));
                }
            }
        }        
        &__title{
            padding-bottom: var(--tit-wrap-padding-bottom-lg);
        }
    }    
    .swiper-slide:not(.swiper-slide-active){
        .introduction{
            &-item{        
                &__image{
                    border-radius: 0;
                }
            }
        }
    }
}
@media (min-width:1024px){
    .introduction{
        --head-height : 138px;
    }
    .swiper-slide-active{
        .introduction-item:hover{  
            .introduction-item__image{
                &__contents{
                    opacity: 1;
                    visibility: visible;
                }
                &::before{
                    opacity: 1;
                    visibility: visible;
                }
                &::after{
                    opacity: 1;
                    visibility: visible;
                    transform: scaleX(1);
                    transition: 0.25s 0.7s ease-out;
                }
                &__text{
                    transform: scale(1);
                    opacity: 1;
                    visibility: visible;
                    transition: 0.5s 0.2s ease-out;
                    &--1{
                        transition-delay: 0.2s;
                    }
                    &--2{
                        transition-delay: 0.3s;
                    }
                    &--3{
                        transition-delay: 0.4s;
                    }
                }
            }
            .introduction-item__contents{
                opacity: 0;
                visibility: hidden;
            }
        }
    }
}
@media (min-width:1200px){
}

