
.main-section{
    &--business{
        background-image: url(/images/main/business-bg.jpg);
        .business-bg{
            position: absolute;
            left: 0;
            &--1{
                top: 0;
            }
            &--2{
                top: 50%;
                transform: translateY(-50%);
            }
            &--3{
                bottom: 0;
            }
        }
    }
    &--introduction{
        background-image: url(/images/main/introduction-bg.jpg);
    }
    &--magazine{
        background-image: url(/images/main/magazine-bg.jpg);
        .magazine-bg{
            position: absolute;
            left: 0;
            &--1{
                bottom: 0;
            }
        }
    }
    &--telinfo{
        background-image: url(/images/main/telinfo-bg.jpg);
    }
    &--contact{
        overflow: hidden;
        background-image: url(/images/main/contact-bg.jpg);
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}
