
.icon-rounded-object{
    width: 100px;
    height: 100px;
    position: absolute;
    left: 0;
    top: 0;
    animation: rotate 7s linear infinite;
}
@media (min-width:576px){
}
@media (min-width:768px){
    .icon-rounded-object{
        width: 180px;
        height: 180px;
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}
@keyframes rotate {
    0%{
        transform: rotate(0);
    }
    100%{
        transform: rotate(360deg);
    }
}
