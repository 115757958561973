
.card-border-point{
    position: relative;
    &::after{
        content: "";
        display: block;
        position: absolute;
        width: 2px;
        height: 100%;
        left: 0;
        top: 0;
        background: linear-gradient(180deg, #B18A00 0%, #FFEA9F 50%, #B18A00 100%);
    }
}
.cards{
    .card-border-point:not(:last-child){
        margin-bottom: 12px;
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .cards{
        .card-border-point:not(:last-child){
            margin-bottom: 24px;
        }
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}

