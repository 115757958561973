
.tit {
    word-break: keep-all;
    font-size: var(--tit-font-size);
    font-weight: var(--tit-font-weight);
    line-height: var(--tit-line-height);
    // size
    &--xxs {
        font-size: var(--tit-font-size-xxs);
    }
    &--xs {
        font-size: var(--tit-font-size-xs);
    }
    &--sm {
        font-size: var(--tit-font-size-sm);
    }
    &--lg {
        font-size: var(--tit-font-size-lg);
    }
    &--xl {
        font-size: var(--tit-font-size-xl);
    }
    &--xxl {
        font-size: var(--tit-font-size-xxl);
    }
}
