
.magazine-card{
    overflow: hidden;
    &__contents{
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
        top: 0;
        left: 0;
        background-image: linear-gradient(to top, rgba(0, 0, 0, .8), transparent);
        display: flex;
        align-items: flex-end;
    }
}
@media (min-width:1544px){
    .magazine-slide.swiper-container{
        width: 1458px;
    }
}
