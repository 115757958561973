
.v-tab--inline {
    &.v-tab{
        &::before{
            display: none;
        }
        &::after{
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 2px;
            left: 0;
            bottom: 0;
            background: linear-gradient(to left, #B18A00 0%, #FFEA9F 50%, #B18A00 100%); 
            transition: 0.2s;
            transform: scaleX(0);
            opacity: 0;
            visibility: hidden;
        }
        &:last-child{
            margin-right: 0 !important;
        }
        &:not(.v-tab--active){
            color: #fff !important;
        }
        &--active {
            background: linear-gradient(180deg, #B18A00 0%, #FFEA9F 50%, #B18A00 100%); 
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-size: 100% 19px;
            background-position: center center;
            &::after{
                transform: scaleX(1);
                opacity: 1;
                visibility: visible;
            }
        }
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .v-tab--inline {
        &.v-tab{
            flex: unset;
            align-items: flex-start;
            &--active {
                background-size: 100% 29px;
                background-position: center top;
            }
        }
    }
}
@media (min-width:1024px){
    .v-tab--inline {
        &.v-tab{
            &:hover{
                &::after{
                    transform: scaleX(1);
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
}
@media (min-width:1200px){
}

